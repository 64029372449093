import React from "react"
import styled from "styled-components"
import LoadingGif from "../loading.gif"

const LoadingWrapper = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 50px;
`

const LoadingContainer = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  background-image: url(${LoadingGif});
  background-size: contain;
  margin-left: 18px;
`

function Loading() {
  return (
    <LoadingWrapper>
      <LoadingContainer />
    </LoadingWrapper>
  )
}

export default Loading
