import styled from "styled-components"

const CloseButton = styled.button`
  color: #333;
  background-color: #fff;
  cursor: ${({ disable }) => (disable ? "" : "pointer")};
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: #ccc;
  font-weight: 400;
  font-size: 14px;

  &:hover {
    color: #333;
    outline: none;
    border: solid 1px #ccc;
    background-color: #e3e2e2;
  }
`

export default CloseButton
