import axios from "axios"
import camelcaseKeys from "camelcase-keys"

async function LoginApi(user) {
  const url = `${process.env.REACT_APP_BASE_URL}/pc/users/login`
  const body = {
    account: user.account,
    password: user.password,
  }

  try {
    const response = await axios.post(url, body)
    if (response.status === 200) {
      const data = camelcaseKeys(response.data, { deep: true })
      return { status: 200, data }
    }

    throw Error("response failed")
  } catch (err) {
    return { status: 400, message: err.message }
  }
}

export default LoginApi
