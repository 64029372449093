import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { AuthProvider } from "./contexts/Authentication"
import PrivateRoute from "./components/PrivateRoute"
import Header from "./components/Header"
import Login from "./pages/Login"
import Revenue from "./pages/Revenue"
import ArticleReads from "./pages/ArticleReads"
import ArticleAnalytics from "./pages/ArticleAnalytics"

function App() {
  return (
    <Router>
      <AuthProvider>
        <Header />
        <HelmetProvider>
          <Helmet>
            <title>Kono出版社後台</title>
          </Helmet>
          <Switch>
            <PrivateRoute exact path="/revenue">
              <Revenue />
            </PrivateRoute>
            <PrivateRoute exact path="/article_reads">
              <ArticleReads />
            </PrivateRoute>
            <PrivateRoute exact path="/article_analytics">
              <ArticleAnalytics />
            </PrivateRoute>
            <Route path="/login">
              <Login />
            </Route>
            <Redirect to="/revenue" />
          </Switch>
        </HelmetProvider>
      </AuthProvider>
    </Router>
  )
}

export default App
