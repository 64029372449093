import { authenticatedApi } from "./index"
import camelcaseKeys from "camelcase-keys"

async function FetchSmarticleMagazineRevenueApi(
  publisherId,
  platform,
  reportNo,
) {
  let total = 0
  const queryString = `publisher_id=${publisherId}&platform=${platform}&time=${reportNo}`
  const url = `${process.env.REACT_APP_BASE_URL}/smarticle/magazine_revenue.json?${queryString}`

  try {
    const response = await authenticatedApi.get(url)
    if (response.status === 200) {
      const revenues = camelcaseKeys(response.data, { deep: true })
      revenues.forEach(revenue => {
        revenue.prices.forEach(price => {
          price.subtotal = price.revenues.reduce((acc, cur) => {
            return parseFloat((acc + cur.subtotal * 0.42).toFixed(2))
          }, 0)
          total += price.subtotal
        })
      })

      total = parseFloat(total.toFixed(2))
      return { status: 200, data: { revenues, total } }
    }

    throw Error("response failed")
  } catch (err) {
    return { status: 400, message: err.message }
  }
}

export default FetchSmarticleMagazineRevenueApi
