import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import BackButton from "../components/BackButton"
import PeriodRevenueTable from "../components/PeriodRevenueTable"
import SmarticlePeriodRevenueTable from "../components/SmarticlePeriodRevenueTable"
import MagazineRevenueTable from "../components/MagazineRevenueTable"
import SmarticleMagazineRevenueTable from "../components/SmarticleMagazineRevenueTable"
import RevenueReadsTable from "../components/RevenueReadsTable"
import ArticleRevenueTable from "../components/ArticleRevenueTable"
import FetchSmarticleMagazineRevenueApi from "../apis/FetchSmarticleMagazineRevenueApi"
import FetchSmarticleArticleRevenueApi from "../apis/FetchSmarticleArticleRevenueApi"
import FetchRevenueReadsApi from "../apis/FetchRevenueReadsApi"

const RevenueWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  border: 1px solid #0066cc;
  padding: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: ${props => (props.step > 0 ? "block" : "none")};
`

const RevenueHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Platform = styled.div`
  width: 50%;
  color: #000;
  font-size: 20px;
  margin: 5px;
  text-align: left;
`

const Summary = styled.div`
  width: ${props => (props.step === 1 ? "100%" : "50%")};
  color: #555555;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: right;
  margin-right: 5px;
`

function RevenueList(props) {
  const {
    step,
    publisherId,
    currency,
    periodRevenues,
    setStep,
    setStep2Info,
  } = props

  const { t } = useTranslation("body")

  const [l2PeriodRevenues, setL2PeriodRevenues] = useState({
    time: null,
    platform: null,
    title: null,
    revenues: null,
    total: 0,
    errorMessage: null,
  })

  const [l3PeriodRevenues, setL3PeriodRevenues] = useState({
    platform: null,
    title: null,
    revenues: null,
    total: 0,
    errorMessage: null,
  })

  const backtoStep1 = () => {
    setL2PeriodRevenues({
      platform: null,
      title: null,
      revenues: null,
      total: 0,
      errorMessage: null,
    })
    setStep(1)
  }

  const backtoStep2 = () => {
    setL3PeriodRevenues({
      platform: null,
      title: null,
      revenues: null,
      total: 0,
      errorMessage: null,
    })
    setStep(2)
  }

  const gotoStep2 = (yyyymm, ver, platform) => {
    setStep(2)
    setStep2Info({
      version: ver,
      time: yyyymm,
      platform: platform.id,
    })
    if (ver === "aycr") {
      const foundObj = periodRevenues.aycr.find(
        element => element["time"] === yyyymm,
      )

      setL2PeriodRevenues({
        time: yyyymm,
        platform: platform.id,
        title: platformName(platform.id),
        revenues: foundObj.revenues,
        total: foundObj.total,
        errorMessage: null,
      })
    }
    if (ver === "smarticle") {
      FetchSmarticleMagazineRevenueApi(publisherId, platform.id, yyyymm).then(
        res => {
          if (res.status === 200) {
            setL2PeriodRevenues({
              time: yyyymm,
              platform: platform.id,
              title: platformName(platform.id),
              revenues: res.data.revenues,
              total: res.data.total,
              errorMessage: null,
            })
          } else {
            setL2PeriodRevenues({
              time: null,
              platform: platform.id,
              title: null,
              revenues: [],
              total: 0,
              errorMessage: res.message,
            })
          }
        },
      )
    }
  }

  const gotoStep3 = (ver, magazineId, total) => {
    setStep(3)
    if (ver === "aycr") {
      FetchRevenueReadsApi(l2PeriodRevenues["time"], magazineId).then(res => {
        if (res.status === 200) {
          setL3PeriodRevenues({
            platform: l2PeriodRevenues["platform"],
            title: l2PeriodRevenues["title"],
            revenues: res.data.revenues,
            total: 0,
            errorMessage: null,
          })
        } else {
          setL3PeriodRevenues({
            platform: l2PeriodRevenues["platform"],
            title: null,
            revenues: [],
            total: 0,
            errorMessage: res.message,
          })
        }
      })
    }
    if (ver === "smarticle") {
      FetchSmarticleArticleRevenueApi(
        l2PeriodRevenues["platform"],
        l2PeriodRevenues["time"],
        magazineId,
      ).then(res => {
        if (res.status === 200) {
          setL3PeriodRevenues({
            platform: l2PeriodRevenues["platform"],
            title: l2PeriodRevenues["title"],
            revenues: res.data.revenues,
            total: total,
            errorMessage: null,
          })
        } else {
          setL3PeriodRevenues({
            platform: l2PeriodRevenues["platform"],
            title: null,
            revenues: [],
            total: 0,
            errorMessage: res.message,
          })
        }
      })
    }
  }

  const platformName = platform => {
    switch (platform) {
      case "kono":
        return "Kono電子雜誌"
      case "udn":
        return "UDN 讀創故事"
      default:
        return platform
    }
  }

  function RenderStep1() {
    return (
      <React.Fragment>
        <Summary step={step}>
          {t("dashboard.totalAmount")}：{` ${periodRevenues.total} ${currency}`}
        </Summary>
        {periodRevenues.aycr.length > 0 ? (
          <React.Fragment>
            <Platform>Kono電子雜誌</Platform>
            <PeriodRevenueTable
              data={periodRevenues.aycr}
              gotoStep2={gotoStep2}
            />
          </React.Fragment>
        ) : null}
        {periodRevenues.smarticle.length > 0
          ? periodRevenues.smarticle.map(platform => (
              <React.Fragment key={platform.platform}>
                <Platform>{platformName(platform.platform)}</Platform>
                <SmarticlePeriodRevenueTable
                  data={platform.revenues}
                  platform={platform.platform}
                  platformName={platform.platform}
                  gotoStep2={gotoStep2}
                />
              </React.Fragment>
            ))
          : null}
      </React.Fragment>
    )
  }

  function RenderStep2() {
    return (
      <React.Fragment>
        <RevenueHeader>
          <Platform>{l2PeriodRevenues.title}</Platform>
          <Summary step={step}>
            {t("dashboard.totalAmount")}：
            {parseFloat(l2PeriodRevenues.total.toFixed(2))}
            {` ${currency}`}
          </Summary>
        </RevenueHeader>
        <BackButton
          onClick={() => {
            backtoStep1()
          }}
        />
        {l2PeriodRevenues.platform === "kono" ? (
          <MagazineRevenueTable data={l2PeriodRevenues} gotoStep3={gotoStep3} />
        ) : (
          <SmarticleMagazineRevenueTable
            data={l2PeriodRevenues}
            gotoStep3={gotoStep3}
          />
        )}
      </React.Fragment>
    )
  }

  function RenderStep3() {
    return (
      <React.Fragment>
        <RevenueHeader>
          <Platform>{l3PeriodRevenues.title}</Platform>
          {l3PeriodRevenues.platform !== "kono" ? (
            <Summary step={step}>
              {t("dashboard.totalAmount")}：{l3PeriodRevenues.total}
              {` ${currency}`}
            </Summary>
          ) : null}
        </RevenueHeader>
        <BackButton
          onClick={() => {
            backtoStep2()
          }}
        />
        {l3PeriodRevenues.platform === "kono" ? (
          <RevenueReadsTable data={l3PeriodRevenues} />
        ) : (
          <ArticleRevenueTable data={l3PeriodRevenues} />
        )}
      </React.Fragment>
    )
  }

  const renderRevenueList = step => {
    switch (step) {
      case 1:
        return <RenderStep1 />
      case 2:
        return <RenderStep2 />
      case 3:
        return <RenderStep3 />
      default:
        return null
    }
  }

  return <RevenueWrapper step={step}>{renderRevenueList(step)}</RevenueWrapper>
}

export default RevenueList
