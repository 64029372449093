import { authenticatedApi } from "./index"
import camelcaseKeys from "camelcase-keys"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

async function FetchArticleReadsApi(magazineId, startDate, endDate) {
  const start = dayjs(startDate).startOf("day").utc(true).unix()
  const end = dayjs(endDate).endOf("day").utc(true).unix()
  const queryString = `start=${start}&end=${end}`
  const url = `${process.env.REACT_APP_BASE_URL}/titles/${magazineId}/revenue_reads?${queryString}`

  try {
    const response = await authenticatedApi.get(url)
    if (response.status === 200) {
      const revenues = camelcaseKeys(response.data, { deep: true })
      return { status: 200, data: { revenues } }
    }

    throw Error("response failed")
  } catch (err) {
    return { status: 400, message: err.message }
  }
}

export default FetchArticleReadsApi
