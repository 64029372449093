import styled from "styled-components"
import Back from "../back.png"

const BackBtn = styled.div`
  width: 32px;
  height: 32px;
  margin: 13px 18px;
  background-image: url(${Back});
`

export default BackBtn
