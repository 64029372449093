import { createContext, useState, useReducer, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { actions } from "./actions"
import { authenticatedApi } from "../apis/index"
import dayjs from "dayjs"

const initialUser = { user: undefined, loading: true }
const AuthContext = createContext(initialUser)

const formatUser = user => {
  return {
    publisherId: user.publisherId || user.account,
    currency: user.currency,
    hasRevenue: user.hasRevenue,
    needSummary: user.needSummary,
    profile: user.profile,
    titles: user.titles,
  }
}

function userReducer(state, action) {
  const { type, payload } = action

  switch (type) {
    case actions.SET_USER:
      if (payload?.token) {
        localStorage.setItem("token", payload.token)
        localStorage.setItem("expireAt", payload.expireAt)
        localStorage.setItem("user", JSON.stringify(formatUser(payload)))
      }
      return formatUser(payload)
    case actions.CLEAR_USER:
      localStorage.removeItem("token")
      localStorage.removeItem("expireAt")
      localStorage.removeItem("user")
      return undefined
    default:
      return state
  }
}

function AuthProvider(props) {
  const [user, dispatchUser] = useReducer(userReducer, undefined)
  const [loading, setLoading] = useState(true)
  let history = useHistory()

  useEffect(() => {
    if (!user) {
      if (localStorage.getItem("user")) {
        const expireAt = parseInt(localStorage.getItem("expireAt"))
        if (expireAt - dayjs().unix() < 86400) {
          authenticatedApi.get("/users/me").then(response => {
            dispatchUser({
              type: actions.SET_USER,
              payload: response.data,
            })
          })
        } else {
          const currentUser = JSON.parse(localStorage.getItem("user"))
          dispatchUser({
            type: actions.SET_USER,
            payload: currentUser,
          })
        }
      } else {
        history.push("/login")
      }
    }
    setLoading(false)
  }, [user, history])

  return (
    <AuthContext.Provider value={{ user, loading, dispatchUser }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext

export { AuthProvider }
