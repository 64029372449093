import styled from "styled-components"
import media from "../components/Media"

const RevenueTable = styled.table`
  display: table;
  width: 100%;
  table-layout: fixed;
  background-color: #eee;
  border-collapse: collapse;
  border-right: 1px solid #cccccc;
  border-spacing: 5px;
  margin-bottom: 30px;

  ${media.mobile`
    font-size: 10px;
  `};
`

const TableTitle = styled.tr`
  display: table-row;
  width: 100%;
  clear: both;
  background-color: #0066cc;
  color: #ffffff;
  border-bottom: 1px solid #cccccc;
`

const TableRow = styled.tr`
  display: table-row;
  clear: both;
  background-color: #fff;
  color: #555555;
`

const TableColumn = styled.td`
  display: table-cell;

  position: relative;
  text-align: center;
  padding: 7px;
  border-left: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
`

export { RevenueTable, TableTitle, TableRow, TableColumn }
