import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

const authenticatedApi = axios.create()

authenticatedApi.interceptors.request.use(config => {
  const headers = { "X-Pc-Token": localStorage.getItem("token") }
  return { ...config, headers }
})

authenticatedApi.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error)
  },
)

export { authenticatedApi }
