import React from "react"
import { useTranslation } from "react-i18next"
import {
  RevenueTable,
  TableTitle,
  TableRow,
  TableColumn,
} from "../components/Table"
import Loading from "../components/Loading"

function PeriodRevenueTable(props) {
  const { data, gotoStep2 } = props
  const { t } = useTranslation("body")

  return data.length > 0 ? (
    <RevenueTable>
      <tbody>
        <TableTitle>
          <TableColumn>
            {t("dashboard.periodsRevenueTable.header.yyyymm")}
          </TableColumn>
          <TableColumn>
            {t("dashboard.periodsRevenueTable.header.amount")}
          </TableColumn>
        </TableTitle>
        {data.map(revenue => (
          <TableRow key={revenue.time}>
            <TableColumn style={{ textDecoration: "underline", width: "40%" }}>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault()
                  gotoStep2(revenue.time, "aycr", { id: "kono" })
                }}
              >
                {revenue.time}
              </a>
            </TableColumn>
            <TableColumn>{parseFloat(revenue.total.toFixed(2))}</TableColumn>
          </TableRow>
        ))}
      </tbody>
    </RevenueTable>
  ) : data.errorMessage ? (
    <div style={{ color: "red" }}>{data.errorMessage}</div>
  ) : (
    <Loading />
  )
}

export default PeriodRevenueTable
