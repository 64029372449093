import React from "react"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"

function DocumentTitle(props) {
  const { t } = useTranslation("body")

  return (
    <Helmet>
      <title>{props.title || t("documentTitle.home")}</title>
    </Helmet>
  )
}

export default DocumentTitle
