import React, { useState } from "react"
import styled from "styled-components"

const DropdownBtn = styled.button`
  min-width: 6rem;
  height: 2rem;
  color: #333;
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  &:focus {
    outline: none;
    border: solid 1px #ffc13c;
    background-color: #ecebea;
    filter: drop-shadow(0px 0px 1.5px rgba(0, 0, 0, 0.5));
  }
`

const OptionBtn = styled.button`
  color: ${props => (props.selected ? "#fff" : "#000")};
  background-color: ${props => (props.selected ? "#428bca" : "#fff")};
  &:hover {
    outline: none;
    color: ${props => (props.selected ? "#fff" : "#000")};
    background-color: ${props => (props.selected ? "#428bca" : "#ecebea")};
  }
`

function DropDown(props) {
  const { data, defaultValue, selectedValue, tag, setValue } = props

  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(isOpen => !isOpen)
  }

  const loseFocus = () => {
    setIsOpen(false)
  }

  const menuClass = `dropdown-menu${isOpen ? " show" : ""}`
  const value = selectedValue || defaultValue
  const selected = data.find(obj => obj.key === value)

  const listItems = data.map(obj => (
    <OptionBtn
      selected={value === obj.key ? true : false}
      className="dropdown-item"
      type="button"
      key={obj.key}
      onMouseDown={() => {
        setValue(tag, obj.key)
      }}
    >
      {obj.value}
    </OptionBtn>
  ))

  return (
    <div
      className="dropdown"
      onFocus={() => {
        toggleOpen()
      }}
      onBlur={() => {
        loseFocus()
      }}
    >
      <DropdownBtn
        className="dropdown-toggle"
        type="button"
        id="dropdownMenu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {selected ? selected.value : value}
      </DropdownBtn>
      <div className={menuClass} aria-labelledby="dropdownMenu">
        {listItems}
      </div>
    </div>
  )
}

export default DropDown
