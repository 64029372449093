import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import zhHant from "./translations/zhHant.json"
import en from "./translations/en.json"
import jp from "./translations/jp.json"

i18n.use(initReactI18next).init({
  resources: {
    "zh-Hant": zhHant,
    "en-US": en,
    "ja-JP": jp,
  },
  lng: "zh-Hant",
  debug: process.env.REACT_APP_CONFIG_FILE !== "production",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

export default i18n
