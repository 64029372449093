import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  RevenueTable,
  TableTitle,
  TableRow,
  TableColumn,
} from "../components/Table"

const RevenueWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  border: 1px solid #0066cc;
  padding: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: "block";
`

const SubMenu = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`

const SubMenuItem = styled(Link)`
  text-decoration: none !important;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 10px;
  cursor: pointer;
  color: ${props => (props.option === props.item ? "#574f39" : "#d5cfc1")};

  &.active {
    color: #574f39;
  }

  :hover {
    color: #574f39;
  }
`

const OtherCountries = styled.div`
  position: absolute;
  top: 0;
  right: 100%;
  width: 300px;
  padding-top: 5px;
  text-align: center;
  background-color: white;
  z-index: 100;
  line-height: 20px;
  border: 1px solid #d5cfc1;
  display: none;
`
const HoverOtherCountries = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;

  &:hover ${OtherCountries} {
    display: block;
  }
`

const List = styled.ul`
  padding: 10px 20px;
  background-color: #fff;
`

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`

function ArticleAnalyticsTable(props) {
  const { data } = props
  const { t } = useTranslation("body")

  const [menuOption, setMenuOption] = useState("gender")

  const menuList = () => {
    return (
      <React.Fragment>
        <SubMenu>
          <SubMenuItem
            to="#"
            item="gender"
            option={menuOption}
            onClick={e => {
              e.preventDefault()
              setMenuOption("gender")
            }}
          >
            {t("dashboard.articleAnalyticsTable.menu.gender")}
          </SubMenuItem>
          <SubMenuItem
            to="#"
            item="location"
            option={menuOption}
            onClick={e => {
              e.preventDefault()
              setMenuOption("location")
            }}
          >
            {t("dashboard.articleAnalyticsTable.menu.location")}
          </SubMenuItem>
        </SubMenu>
      </React.Fragment>
    )
  }

  return data.revenues ? (
    <RevenueWrapper>
      {menuList()}
      <RevenueTable>
        <tbody>
          <TableTitle>
            <TableColumn style={{ width: "5%" }}>
              {t("dashboard.articleAnalyticsTable.header.sort")}
            </TableColumn>
            <TableColumn style={{ width: "35%" }}>
              {t("dashboard.articleAnalyticsTable.header.article")}
            </TableColumn>
            <TableColumn>
              {t("dashboard.articleAnalyticsTable.header.issue")}
            </TableColumn>
            {menuOption === "gender" ? (
              <React.Fragment>
                <TableColumn>
                  {t("dashboard.articleAnalyticsTable.header.gender.male")}
                </TableColumn>
                <TableColumn>
                  {t("dashboard.articleAnalyticsTable.header.gender.female")}
                </TableColumn>
                <TableColumn>
                  {t("dashboard.articleAnalyticsTable.header.gender.unknown")}
                </TableColumn>
              </React.Fragment>
            ) : menuOption === "location" ? (
              <React.Fragment>
                <TableColumn>
                  {t("dashboard.articleAnalyticsTable.header.location.taiwan")}
                </TableColumn>
                <TableColumn>
                  {t("dashboard.articleAnalyticsTable.header.location.china")}
                </TableColumn>
                <TableColumn>
                  {t("dashboard.articleAnalyticsTable.header.location.us")}
                </TableColumn>
                <TableColumn>
                  {t("dashboard.articleAnalyticsTable.header.location.hk")}
                </TableColumn>
                <TableColumn>
                  {t(
                    "dashboard.articleAnalyticsTable.header.location.malaysia",
                  )}
                </TableColumn>
                <TableColumn>
                  {t("dashboard.articleAnalyticsTable.header.location.other")}
                </TableColumn>
              </React.Fragment>
            ) : null}
          </TableTitle>
          {data.revenues.revenues.map((revenue, idx) => (
            <TableRow key={revenue.articleId}>
              <TableColumn>{idx + 1}</TableColumn>
              <TableColumn>
                <a
                  href={`https://www.thekono.com/articles/${revenue.articleId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {revenue.articleTitle}
                </a>
              </TableColumn>
              <TableColumn>{revenue.articleIssue}</TableColumn>
              {menuOption === "gender" ? (
                <React.Fragment>
                  <TableColumn>{revenue.gender[1]}</TableColumn>
                  <TableColumn>{revenue.gender[2]}</TableColumn>
                  <TableColumn>{revenue.gender[0]}</TableColumn>
                </React.Fragment>
              ) : menuOption === "location" ? (
                <React.Fragment>
                  <TableColumn>{revenue.formattedLocation.taiwan}</TableColumn>
                  <TableColumn>{revenue.formattedLocation.china}</TableColumn>
                  <TableColumn>{revenue.formattedLocation.us}</TableColumn>
                  <TableColumn>{revenue.formattedLocation.hk}</TableColumn>
                  <TableColumn>
                    {revenue.formattedLocation.malaysia}
                  </TableColumn>
                  <TableColumn>
                    {revenue.formattedLocation.others.count > 0 ? (
                      <React.Fragment>
                        <HoverOtherCountries>
                          {revenue.formattedLocation.others.count}
                          <OtherCountries>
                            <List>
                              {revenue.formattedLocation.others.countries.map(
                                (country, cIdx) => (
                                  <ListItem key={cIdx}>
                                    {country.name} : {country.count}
                                  </ListItem>
                                ),
                              )}
                            </List>
                          </OtherCountries>
                        </HoverOtherCountries>
                      </React.Fragment>
                    ) : (
                      revenue.formattedLocation.others.count
                    )}
                  </TableColumn>
                </React.Fragment>
              ) : null}
            </TableRow>
          ))}
        </tbody>
      </RevenueTable>
    </RevenueWrapper>
  ) : data.errorMessage ? (
    <div style={{ color: "red" }}>{data.errorMessage}</div>
  ) : null
}

export default ArticleAnalyticsTable
