import { authenticatedApi } from "./index"
import camelcaseKeys from "camelcase-keys"

async function FetchPeriodRevenueApi(publisherId, period) {
  let total = 0
  const queryString = `publisher_id=${publisherId}&start_year=${period["startYear"]}&start_month=${period["startMonth"]}&end_year=${period["endYear"]}&end_month=${period["endMonth"]}`

  const url = `${process.env.REACT_APP_BASE_URL}/titles/periods_revenue.json?${queryString}`

  try {
    const response = await authenticatedApi.get(url)
    if (response.status === 200) {
      const revenues = camelcaseKeys(response.data, { deep: true })
      total =
        revenues.aycr.reduce((acc, cur) => {
          return acc + cur.total
        }, 0) +
        revenues.smarticle.reduce((acc, cur) => {
          return acc + cur.total
        }, 0)

      total = parseFloat(total.toFixed(2))
      return { status: 200, data: { revenues, total } }
    }

    throw Error("response failed")
  } catch (err) {
    return { status: 400, message: err.message }
  }
}

export default FetchPeriodRevenueApi
