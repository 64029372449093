import { authenticatedApi } from "./index"
import camelcaseKeys from "camelcase-keys"

async function FetchRevenueReadsApi(yyyymm, magazineId) {
  const y = parseInt(yyyymm / 100)
  const m = parseInt(yyyymm % 100)
  const queryString = `year=${y}&month=${m}`
  const url = `${process.env.REACT_APP_BASE_URL}/titles/${magazineId}/cached_reads?${queryString}`

  try {
    const response = await authenticatedApi.get(url)
    if (response.status === 200) {
      const revenues = camelcaseKeys(response.data, { deep: true })
      return { status: 200, data: { revenues } }
    }

    throw Error("response failed")
  } catch (err) {
    return { status: 400, message: err.message }
  }
}

export default FetchRevenueReadsApi
