import React from "react"
import styled from "styled-components"
import Loading from "../loading.svg"

const LoadingWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  opacity: 0.4;
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  background-image: url(${Loading});
  background-size: contain;
`

function LoadingView(props) {
  const { loading } = props

  return loading ? (
    <LoadingWrapper>
      <LoadingContainer />
    </LoadingWrapper>
  ) : null
}

export default LoadingView
