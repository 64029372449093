import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {
  RevenueTable,
  TableTitle,
  TableRow,
  TableColumn,
} from "../components/Table"

const RevenueWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  border: 1px solid #0066cc;
  padding: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: "block";
`

function ArticleReadsTable(props) {
  const { data } = props
  const { t } = useTranslation("body")

  return data.revenues ? (
    <RevenueWrapper>
      <RevenueTable>
        <tbody>
          <TableTitle>
            <TableColumn>
              {t("dashboard.revenueReadsTable.header.sort")}
            </TableColumn>
            <TableColumn style={{ width: "45%" }}>
              {t("dashboard.revenueReadsTable.header.article")}
            </TableColumn>
            <TableColumn style={{ width: "20%" }}>
              {t("dashboard.revenueReadsTable.header.issue")}
            </TableColumn>
            <TableColumn>
              {t("dashboard.revenueReadsTable.header.reading")}
            </TableColumn>
            <TableColumn>
              {t("dashboard.revenueReadsTable.header.readingWithWorkWeight")}
            </TableColumn>
            <TableColumn style={{ width: "10%" }}>
              {t("dashboard.revenueReadsTable.header.averageReadingTime")}
            </TableColumn>
          </TableTitle>
          {data.revenues.revenues.map(revenue =>
            revenue.articleReads.map((article, idx) => (
              <TableRow key={article.articleId}>
                <TableColumn>{idx + 1}</TableColumn>
                <TableColumn>
                  <a
                    href={`https://www.thekono.com/articles/${article.articleId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {article.title}
                  </a>
                </TableColumn>
                <TableColumn>{article.issue}</TableColumn>
                <TableColumn>{article.count}</TableColumn>
                <TableColumn>
                  {Math.round(
                    100 *
                      (revenue.weight * article.count * article.extraWeight),
                  ) / 100}
                </TableColumn>
                <TableColumn>
                  {Math.round(article.duration * 100) / 100}
                </TableColumn>
              </TableRow>
            )),
          )}
        </tbody>
      </RevenueTable>
    </RevenueWrapper>
  ) : data.errorMessage ? (
    <div style={{ color: "red" }}>{data.errorMessage}</div>
  ) : null
}

export default ArticleReadsTable
