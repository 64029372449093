import { authenticatedApi } from "./index"
import camelcaseKeys from "camelcase-keys"

async function UpdatePasswordApi(id, pwd) {
  const url = `${process.env.REACT_APP_BASE_URL}/pc/users/password/update`
  const body = {
    id: id,
    password: pwd["oldPassword"],
    new_password: pwd["newPassword"],
  }

  try {
    const response = await authenticatedApi.post(url, body)
    if (response.status === 200) {
      const res = camelcaseKeys(response.data, { deep: true })
      return { status: 200, result: res.result }
    }

    throw Error("response failed")
  } catch (err) {
    return { status: 400, message: err.message }
  }
}

export default UpdatePasswordApi
