import React from "react"
import { useTranslation } from "react-i18next"
import {
  RevenueTable,
  TableTitle,
  TableRow,
  TableColumn,
} from "../components/Table"
import Loading from "../components/Loading"

function SmarticlePeriodRevenueTable(props) {
  const { data, platform, platformName, gotoStep2 } = props
  const { t } = useTranslation("body")

  return data.length > 0 ? (
    <RevenueTable>
      <tbody>
        <TableTitle>
          <TableColumn>
            {t("dashboard.periodsRevenueTable.header.yyyymm")}
          </TableColumn>
          <TableColumn>
            {t("dashboard.periodsRevenueTable.header.amount")}
          </TableColumn>
        </TableTitle>
        {data.map(revenue => (
          <TableRow key={revenue.time}>
            {revenue.revenue > 0 ? (
              <TableColumn
                style={{ textDecoration: "underline", width: "40%" }}
              >
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    gotoStep2(revenue.time, "smarticle", {
                      id: platform,
                      name: platformName,
                    })
                  }}
                >
                  {revenue.time}
                </a>
              </TableColumn>
            ) : (
              <TableColumn>{revenue.time}</TableColumn>
            )}
            <TableColumn>{revenue.revenue}</TableColumn>
          </TableRow>
        ))}
      </tbody>
    </RevenueTable>
  ) : data.errorMessage ? (
    <div style={{ color: "red" }}>{data.errorMessage}</div>
  ) : (
    <Loading />
  )
}

export default SmarticlePeriodRevenueTable
