import React from "react"
import { useTranslation } from "react-i18next"
import {
  RevenueTable,
  TableTitle,
  TableRow,
  TableColumn,
} from "../components/Table"
import ToolTip from "../components/ToolTip"
import Loading from "../components/Loading"

function ArticleRevenueTable(props) {
  const { data } = props
  const { t } = useTranslation("body")

  return data.revenues ? (
    <RevenueTable>
      <tbody>
        <TableTitle>
          <TableColumn>
            {t("dashboard.articleRevenueTable.header.article")}
          </TableColumn>
          <TableColumn>
            {t("dashboard.articleRevenueTable.header.issue")}
          </TableColumn>
          <TableColumn>
            {t("dashboard.articleRevenueTable.header.salesVolume")}
          </TableColumn>
          <TableColumn>
            <ToolTip tip={t("dashboard.articleRevenueTable.header.tip")} />
            {t("dashboard.articleRevenueTable.header.amount")}
          </TableColumn>
        </TableTitle>
        {data.revenues.map(revenue => (
          <TableRow key={revenue.articleId}>
            <TableColumn>{revenue.articleTitle}</TableColumn>
            <TableColumn>{revenue.issueName}</TableColumn>
            <TableColumn>{revenue.counts}</TableColumn>
            <TableColumn>{revenue.publisherRevenue}</TableColumn>
          </TableRow>
        ))}
      </tbody>
    </RevenueTable>
  ) : data.errorMessage ? (
    <div style={{ color: "red" }}>{data.errorMessage}</div>
  ) : (
    <Loading />
  )
}

export default ArticleRevenueTable
