import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  RevenueTable,
  TableTitle,
  TableRow,
  TableColumn,
} from "../components/Table"
import SubmitButton from "../components/SubmitButton"
import PriceListView from "../components/PriceListView"

function MagazineRevenueTable(props) {
  const { data, gotoStep3 } = props
  const { t } = useTranslation("body")

  const [priceList, setPriceList] = useState(null)

  const magazineCounts = articleReadsGroup => {
    const total = articleReadsGroup.reduce(
      (acc, argbw) => acc + argbw.count * argbw.weight.weight,
      0,
    )

    return parseFloat(total.toFixed(2))
  }

  return data.revenues ? (
    <React.Fragment>
      <PriceListView data={priceList} setPriceList={setPriceList} />
      <RevenueTable>
        <tbody>
          <TableTitle>
            <TableColumn style={{ width: "20%" }}>
              {t("dashboard.magazineRevenueTable.header.title")}
            </TableColumn>
            <TableColumn style={{ width: "20%" }}>
              {t("dashboard.magazineRevenueTable.header.status")}
            </TableColumn>
            <TableColumn>
              {t("dashboard.magazineRevenueTable.header.workWeight")}
            </TableColumn>
            <TableColumn>
              {t("dashboard.magazineRevenueTable.header.reading")}
            </TableColumn>
            <TableColumn>
              {t("dashboard.magazineRevenueTable.header.readingWithWorkWeight")}
            </TableColumn>
            <TableColumn>
              {t("dashboard.magazineRevenueTable.header.totalReading")}
            </TableColumn>
            <TableColumn>
              {t("dashboard.magazineRevenueTable.header.amount")}
            </TableColumn>
            <TableColumn>
              {t("dashboard.magazineRevenueTable.header.amountPayable")}
            </TableColumn>
          </TableTitle>
          {data.revenues.map(revenue =>
            revenue.revenue.revenue > 0
              ? revenue.revenue.articleReadsGroupByWeight.map(argbw =>
                  revenue.revenue.articleReadsGroupByWeight.indexOf(argbw) ===
                  0 ? (
                    <TableRow
                      key={revenue.title + "-" + argbw.weight.timeBound}
                    >
                      <TableColumn
                        style={{ textDecoration: "underline" }}
                        rowSpan={
                          revenue.revenue.articleReadsGroupByWeight.length
                        }
                      >
                        <a
                          href="/"
                          onClick={e => {
                            e.preventDefault()
                            gotoStep3("aycr", revenue.title)
                          }}
                        >
                          {revenue.name}
                        </a>
                      </TableColumn>
                      <TableColumn>{argbw.weight.tag}</TableColumn>
                      <TableColumn>{argbw.weight.weight}</TableColumn>
                      <TableColumn>{argbw.count}</TableColumn>
                      <TableColumn>
                        {argbw.count * argbw.weight.weight}
                      </TableColumn>
                      <TableColumn
                        rowSpan={
                          revenue.revenue.articleReadsGroupByWeight.length
                        }
                      >
                        {magazineCounts(
                          revenue.revenue.articleReadsGroupByWeight,
                        )}
                      </TableColumn>
                      <TableColumn
                        rowSpan={
                          revenue.revenue.articleReadsGroupByWeight.length
                        }
                      >
                        {parseFloat(revenue.revenue.revenue.toFixed(2))}
                      </TableColumn>
                      <TableColumn
                        rowSpan={
                          revenue.revenue.articleReadsGroupByWeight.length
                        }
                      >
                        <SubmitButton
                          onClick={e => {
                            e.preventDefault()
                            setPriceList(revenue)
                          }}
                        >
                          {t("dashboard.magazineRevenueTable.button.detail")}
                        </SubmitButton>
                      </TableColumn>
                    </TableRow>
                  ) : (
                    <TableRow
                      key={revenue.title + "-" + argbw.weight.timeBound}
                    >
                      <TableColumn>{argbw.weight.tag}</TableColumn>
                      <TableColumn>{argbw.weight.weight}</TableColumn>
                      <TableColumn>{argbw.count}</TableColumn>
                      <TableColumn>
                        {parseFloat(
                          (argbw.count * argbw.weight.weight).toFixed(2),
                        )}
                      </TableColumn>
                    </TableRow>
                  ),
                )
              : null,
          )}
        </tbody>
      </RevenueTable>
    </React.Fragment>
  ) : data.errorMessage ? (
    <div style={{ color: "red" }}>{data.errorMessage}</div>
  ) : null
}

export default MagazineRevenueTable
