import { css } from "styled-components"

const media = {
  desktop: (...args) => css`
    @media (min-width: 800px) {
      ${css(...args)};
    }
  `,

  mobile: (...args) => css`
    @media (max-width: 799px) {
      ${css(...args)};
    }
  `,
}

export default media
