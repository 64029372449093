import React, { useState, useContext } from "react"
import { useHistory, Redirect } from "react-router-dom"
import styled from "styled-components"
import DocumentTitle from "../components/DocumentTitle"
import Button from "../components/Button"
import LoadingView from "../components/LoadingView"
import AuthContext from "../contexts/Authentication"
import { actions } from "../contexts/actions"
import LoginApi from "../apis/LoginApi"
import i18n from "../i18n"
import languageCode from "../utils/languageCode"

const Wrapper = styled.div`
  background-color: #f7f5f0;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  padding: 3rem;
  height: 100vh;
  max-width: 70%;
  margin: 0 auto;
  padding-top: 8rem;
`

const Form = styled.form`
  width: 70%;
`

const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  border: 1px solid #efefef;
  &:focus {
    outline: none;
    border: solid 2px #ffc13c;
  }
`

function Login() {
  const [account, setAccount] = useState("")
  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  let history = useHistory()

  const { user, dispatchUser } = useContext(AuthContext)

  const onSubmit = async () => {
    try {
      setLoading(true)
      const response = await LoginApi({ account: account, password: password })
      if (response?.data) {
        dispatchUser({
          type: actions.SET_USER,
          payload: response.data,
        })

        const eventProperties = { admin: response.data?.isAdmin }
        window.amplitude.getInstance().setUserId(account)
        window.amplitude.getInstance().logEvent("logged in", eventProperties)

        const userCurrency = response.data?.currency || "USD"
        i18n.changeLanguage(languageCode(userCurrency))

        history.push("/revenue")
      } else {
        setErrorMessage("Account or Password is incorrect")
      }
    } catch (e) {
      console.log(e)
      setErrorMessage("")
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <DocumentTitle title="Kono 出版社後台 - 登入" />
      {user ? (
        <Redirect to="/revenue" />
      ) : (
        <Wrapper>
          <LoadingView loading={loading} />
          <React.Fragment>
            <Form
              onSubmit={e => {
                e.preventDefault()
                onSubmit()
              }}
            >
              <Input
                type="text"
                placeholder="Account"
                onChange={e => setAccount(e.target.value)}
              />
              <Input
                type="password"
                placeholder="Password"
                onChange={e => setPassword(e.target.value)}
              />
              <p style={{ color: "red" }}>{errorMessage}</p>
              <Button type="submit" className="btn btn-block">
                Login
              </Button>
            </Form>
          </React.Fragment>
        </Wrapper>
      )}
    </React.Fragment>
  )
}

export default Login
