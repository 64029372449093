import React from "react"
import styled from "styled-components"

const TipContent = styled.div`
  position: absolute;
  width: 200px;
  color: #000;
  font-size: 12px;
  margin-top: -4.2rem;
  margin-left: -8rem;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #cccccc;
  text-align: left;
  display: none;
`

const TipOnHover = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 30px 0;
  border-color: transparent #a1a1a1 transparent transparent;

  p {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    margin-right: -40px;
  }

  &:hover ${TipContent} {
    display: block;
  }
`

function ToolTip(props) {
  return (
    <TipOnHover>
      <TipContent>{props.tip}</TipContent>
      <p>!</p>
    </TipOnHover>
  )
}

export default ToolTip
