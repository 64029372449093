import React from "react"
import { useTranslation } from "react-i18next"
import {
  RevenueTable,
  TableTitle,
  TableRow,
  TableColumn,
} from "../components/Table"
import ToolTip from "../components/ToolTip"
import Loading from "../components/Loading"

function SmarticleMagazineRevenueTable(props) {
  const { data, gotoStep3 } = props
  const { t } = useTranslation("body")

  const magazineRowspan = revenue => {
    let rowspan = 0
    revenue.prices.map(price => (rowspan += price.revenues.length))

    return rowspan
  }

  return data.revenues ? (
    <RevenueTable>
      <tbody>
        <TableTitle>
          <TableColumn colSpan={2}>
            {t("dashboard.smarticleMagazineRevenueTable.header.title")}
          </TableColumn>
          <TableColumn>
            {t("dashboard.smarticleMagazineRevenueTable.header.discount")}
          </TableColumn>
          <TableColumn>
            {t("dashboard.smarticleMagazineRevenueTable.header.price")}
          </TableColumn>
          <TableColumn>
            {t("dashboard.smarticleMagazineRevenueTable.header.salesVolume")}
          </TableColumn>
          <TableColumn>
            {t("dashboard.smarticleMagazineRevenueTable.header.amount")}
          </TableColumn>
          <TableColumn>
            <ToolTip
              tip={t("dashboard.smarticleMagazineRevenueTable.header.tip")}
            />
            {t(
              "dashboard.smarticleMagazineRevenueTable.header.revenueShareRate",
            )}
          </TableColumn>
          <TableColumn>
            {t("dashboard.smarticleMagazineRevenueTable.header.totalAmount")}
          </TableColumn>
        </TableTitle>
        {data.revenues.map(revenue =>
          revenue.prices.map(price =>
            price.revenues.map(r =>
              price.revenues.indexOf(r) === 0 ? (
                <TableRow
                  key={revenue.mId + "-" + price.pId + "-" + r.salePrice}
                >
                  {revenue.prices.indexOf(price) === 0 ? (
                    <TableColumn
                      rowSpan={magazineRowspan(revenue)}
                      style={{ textDecoration: "underline" }}
                    >
                      <a
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                          gotoStep3("smarticle", revenue.mId, price.subtotal)
                        }}
                      >
                        {revenue.name}
                      </a>
                    </TableColumn>
                  ) : null}
                  <TableColumn rowSpan={price.revenues.length}>
                    {price.title}
                  </TableColumn>
                  <TableColumn>{Math.floor(r.percent * 100)}%</TableColumn>
                  <TableColumn>{r.salePrice}</TableColumn>
                  <TableColumn>{r.counts}</TableColumn>
                  <TableColumn>{r.subtotal}</TableColumn>
                  <TableColumn>42%</TableColumn>
                  <TableColumn rowSpan={price.revenues.length}>
                    {price.subtotal}
                  </TableColumn>
                </TableRow>
              ) : (
                <TableRow
                  key={revenue.mId + "-" + price.pId + "-" + r.salePrice}
                >
                  <TableColumn>{Math.floor(r.percent * 100)}%</TableColumn>
                  <TableColumn>{r.salePrice}</TableColumn>
                  <TableColumn>{r.counts}</TableColumn>
                  <TableColumn>{r.subtotal}</TableColumn>
                  <TableColumn>42%</TableColumn>
                </TableRow>
              ),
            ),
          ),
        )}
      </tbody>
    </RevenueTable>
  ) : data.errorMessage ? (
    <div style={{ color: "red" }}>{data.errorMessage}</div>
  ) : (
    <Loading />
  )
}

export default SmarticleMagazineRevenueTable
