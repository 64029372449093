import { useContext } from "react"
import { Route, Redirect } from "react-router-dom"
import AuthContext from "../contexts/Authentication"

function PrivateRoute({ children, ...rest }) {
  const { user, loading } = useContext(AuthContext)

  return loading ? null : (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  )
}

export default PrivateRoute
