import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import CloseButton from "../components/CloseButton"
import media from "../components/Media"

const Mask = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`

const RevenueTable = styled.table`
  display: "table";
  width: 100%;
  table-layout: fixed;
  background-color: #eee;
  border-collapse: collapse;
  border-right: 1px solid #cccccc;
  border-spacing: 5px;
  margin-bottom: 30px;
`

const TableTitle = styled.tr`
  display: table-row;
  width: 100%;
  clear: both;
  background-color: #0066cc;
  color: #ffffff;
  border-bottom: 1px solid #cccccc;
`

const TableRow = styled.tr`
  display: table-row;
  clear: both;
  background-color: #fff;
  color: #555555;
`

const TableColumn = styled.td`
  display: table-cell;
  position: relative;
  text-align: center;
  padding: 7px;
  border-left: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
`

const Modal = styled.div`
  display: block;
  position: fixed;
  width: 600px;
  top: 50%;
  left: 50%;
  overflow: hidden;
  outline: 0;
  z-index: 1050;
  transform: translate(-50%, -50%);

  ${media.mobile`
    width: calc(100vw - 40px);
  `};
`

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
`

const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
`

const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
`

function PriceListView(props) {
  const { data, setPriceList } = props
  const { t } = useTranslation("body")

  return data ? (
    <React.Fragment>
      <Mask
        onMouseDown={() => {
          setPriceList(null)
        }}
      />
      <Modal>
        <ModalContent>
          <ModalHeader>
            <p className="modal-title" style={{ margin: "0 auto" }}>{`${
              data.name
            } ${t("dashboard.priceList.title")}`}</p>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={e => {
                e.preventDefault()
                setPriceList(null)
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            <RevenueTable>
              <tbody>
                <TableTitle>
                  <TableColumn>
                    {t("dashboard.priceList.header.numberOfReading")}
                  </TableColumn>
                  <TableColumn>
                    {t("dashboard.priceList.header.amountPayable")}
                  </TableColumn>
                </TableTitle>
                <TableRow>
                  <TableColumn>0 ~ 1000</TableColumn>
                  <TableColumn>{data.revenue.levels[0]}</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>1001 ~ 5000</TableColumn>
                  <TableColumn>{data.revenue.levels[1]}</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>5001 ~ 10000</TableColumn>
                  <TableColumn>{data.revenue.levels[2]}</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>10001 ~ 20000</TableColumn>
                  <TableColumn>{data.revenue.levels[3]}</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>20001 ~ 50000</TableColumn>
                  <TableColumn>{data.revenue.levels[4]}</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>50001 ~ 100000</TableColumn>
                  <TableColumn>{data.revenue.levels[5]}</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>100001 ~ 500000</TableColumn>
                  <TableColumn>{data.revenue.levels[6]}</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>500000+</TableColumn>
                  <TableColumn>{data.revenue.levels[7]}</TableColumn>
                </TableRow>
              </tbody>
            </RevenueTable>
          </ModalBody>
          <ModalFooter>
            <CloseButton
              onClick={e => {
                e.preventDefault()
                setPriceList(null)
              }}
            >
              {t("dashboard.priceList.button.close")}
            </CloseButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  ) : null
}

export default PriceListView
