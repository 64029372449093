const languageCode = currency => {
  let code = "en-US"

  switch (currency) {
    case "NTD":
    case "HKD":
      code = "zh-Hant"
      break
    case "YEN":
      code = "ja-JP"
      break
    default:
      break
  }
  return code
}

export default languageCode
