import React from "react"
import { useTranslation } from "react-i18next"
import {
  RevenueTable,
  TableTitle,
  TableRow,
  TableColumn,
} from "../components/Table"
import convertTimeBound from "../utils/convertTimeBound"
import Loading from "../components/Loading"

function RevenueReadsTable(props) {
  const { data } = props
  const { t } = useTranslation("body")

  const issueTimeBound = (groups, index) => {
    if (groups[index].timeBound === 0) {
      return t("dashboard.magazineRevenueTable.data.currentIssue")
    } else if (index === groups.length - 1) {
      if (groups[index - 1].timeBound === 0) {
        return t("dashboard.magazineRevenueTable.data.backIssue")
      }

      return `${convertTimeBound(groups[index - 1].timeBound)} ${t(
        "dashboard.magazineRevenueTable.data.longTermBackIssue",
      )}`
    }

    return `${convertTimeBound(
      groups[index - 1].timeBound,
    )} ~ ${convertTimeBound(groups[index].timeBound)} ${t(
      "dashboard.magazineRevenueTable.data.shortTermBackIssue",
    )}`
  }

  return data.revenues.length > 0 ? (
    <RevenueTable>
      <tbody>
        <TableTitle>
          <TableColumn style={{ width: "5%" }}>
            {t("dashboard.revenueReadsTable.header.sort")}
          </TableColumn>
          <TableColumn style={{ width: "50%" }}>
            {t("dashboard.revenueReadsTable.header.article")}
          </TableColumn>
          <TableColumn>
            {t("dashboard.revenueReadsTable.header.issue")}
          </TableColumn>
          <TableColumn>
            {t("dashboard.revenueReadsTable.header.status")}
          </TableColumn>
          <TableColumn style={{ width: "5%" }}>
            {t("dashboard.revenueReadsTable.header.workWeight")}
          </TableColumn>
          <TableColumn style={{ width: "5%" }}>
            {t("dashboard.revenueReadsTable.header.reading")}
          </TableColumn>
          <TableColumn style={{ width: "5%" }}>
            {t("dashboard.revenueReadsTable.header.readingWithWorkWeight")}
          </TableColumn>
          <TableColumn>
            {t("dashboard.revenueReadsTable.header.averageReadingTime")}
          </TableColumn>
        </TableTitle>
        {data.revenues.map((revenue, rIdx) =>
          revenue.articleReads.map((article, idx) =>
            article.extraWeight > 0 ? (
              <TableRow key={article.articleId}>
                <TableColumn>{idx + 1}</TableColumn>
                <TableColumn>
                  <a
                    href={`https://www.thekono.com/articles/${article.articleId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {article.title}
                  </a>
                </TableColumn>
                <TableColumn>{article.issue}</TableColumn>
                <TableColumn>{issueTimeBound(data.revenues, rIdx)}</TableColumn>
                <TableColumn>{revenue.weight}</TableColumn>
                <TableColumn>{article.count}</TableColumn>
                <TableColumn>{article.count * article.extraWeight}</TableColumn>
                <TableColumn>{article.duration.toFixed(2)}</TableColumn>
              </TableRow>
            ) : null,
          ),
        )}
      </tbody>
    </RevenueTable>
  ) : data.errorMessage ? (
    <div style={{ color: "red" }}>{data.errorMessage}</div>
  ) : (
    <Loading />
  )
}

export default RevenueReadsTable
