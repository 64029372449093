import styled from "styled-components"

const Button = styled.button`
  background-color: #00a591;
  border: none;
  cursor: ${({ disable }) => (disable ? "" : "pointer")};
  color: #fff;
  :hover,
  :focus,
  :active,
  :active:hover {
    color: #fff;
    background-color: #00a591;
    border-color: #00a591;
  }
  background-color: ${({ bgColor, disable }) =>
    disable ? "#9a9a9a" : bgColor ? bgColor : "#072149"};
  :hover {
    background-color: ${({ hoverColor, disable }) =>
      disable ? "#9a9a9a" : hoverColor ? hoverColor : "#0B3371"};
  }
`

export default Button
