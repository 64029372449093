import { authenticatedApi } from "./index"
import camelcaseKeys from "camelcase-keys"

async function FetchSmarticleArticleRevenueApi(platform, reportNo, magazineId) {
  const queryString = `magazine_id=${magazineId}&time=${reportNo}&platform=${platform}`
  const url = `${process.env.REACT_APP_BASE_URL}/smarticle/article_revenue?${queryString}`

  try {
    const response = await authenticatedApi.get(url)
    if (response.status === 200) {
      const revenues = camelcaseKeys(response.data, { deep: true })
      const total = revenues.reduce(
        (acc, cur) => acc + parseFloat(cur.publisherRevenue),
        0,
      )
      return { status: 200, data: { revenues, total } }
    }

    throw Error("response failed")
  } catch (err) {
    return { status: 400, message: err.message }
  }
}

export default FetchSmarticleArticleRevenueApi
