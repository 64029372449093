const token = () => {
  return `&x_pc_token=${localStorage.getItem("token")}`
}

const periodReportLink = (publisherId, period) => {
  const queryString = `publisher_id=${publisherId}&start_year=${period["startYear"]}&start_month=${period["startMonth"]}&end_year=${period["endYear"]}&end_month=${period["endMonth"]}`
  const url = `${
    process.env.REACT_APP_BASE_URL
  }/titles/periods_revenue.xlsx?${queryString}${token()}`

  return url
}

const magazineReportLink = (publisherId, step2Info) => {
  const year = parseInt(step2Info.time / 100)
  const month = parseInt(step2Info.time % 100)
  const queryString = `publisher_id=${publisherId}&year=${year}&month=${month}`
  let url = `${
    process.env.REACT_APP_BASE_URL
  }/titles/revenue.xlsx?${queryString}${token()}`

  if (step2Info.version === "smarticle") {
    const platform = step2Info.platform
    url = `${
      process.env.REACT_APP_BASE_URL
    }/smarticle/magazine_revenue.xlsx?${queryString}&platform=${platform}${token()}`
  }
  return url
}

export { periodReportLink, magazineReportLink }
