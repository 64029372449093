import styled from "styled-components"
import media from "../components/Media"

const SubmitButton = styled.button`
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  border: none;
  cursor: ${({ disable }) => (disable ? "" : "pointer")};
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 14px;

  &:focus {
    color: #fff;
    outline: none;
    border: solid 1px #ffc13c;
    background-color: #3276b1;
  }

  ${media.mobile`
    margin-top: 10px;
  `};
`

export default SubmitButton
