import { authenticatedApi } from "./index"
import camelcaseKeys from "camelcase-keys"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

const formattedLocation = location => {
  const fl = location.reduce(
    (acc, cur) => {
      switch (cur.name) {
        case "Taiwan":
          acc.taiwan += cur.count
          break
        case "China":
          acc.china += cur.count
          break
        case "United States":
          acc.us += cur.count
          break
        case "Hong Kong":
          acc.hk += cur.count
          break
        case "Malaysia":
          acc.malaysia += cur.count
          break
        default:
          acc.others.count += cur.count
          acc.others.countries.push(cur)
      }

      return acc
    },
    {
      taiwan: 0,
      china: 0,
      us: 0,
      hk: 0,
      malaysia: 0,
      others: { count: 0, countries: [] },
    },
  )

  return fl
}

async function FetchArticleAnalyticsApi(magazineId, startDate, endDate) {
  const beginAt = dayjs(startDate).startOf("day").utc(true).unix()
  const endAt = dayjs(endDate).endOf("day").utc(true).unix()
  const queryString = `begin_at=${beginAt}&end_at=${endAt}`
  const url = `${process.env.REACT_APP_BASE_URL}/titles/${magazineId}/article_analytics?${queryString}`

  try {
    const response = await authenticatedApi.get(url)
    if (response.status === 200) {
      let revenues = camelcaseKeys(response.data, { deep: true })
      revenues.forEach(revenue => {
        revenue.formattedLocation = formattedLocation(revenue.location)
      })
      return { status: 200, data: { revenues } }
    }

    throw Error("response failed")
  } catch (err) {
    return { status: 400, message: err.message }
  }
}

export default FetchArticleAnalyticsApi
