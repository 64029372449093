import React, { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import AuthContext from "../contexts/Authentication"
import FetchArticleReadsApi from "../apis/FetchArticleReadsApi"
import DocumentTitle from "../components/DocumentTitle"
import ArticleReadsTable from "../components/ArticleReadsTable"
import DropDown from "../components/DropDown"
import LoadingGif from "../loading.gif"
import media from "../components/Media"
import dayjs from "dayjs"

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;

  ${media.desktop`
    width: 85%;
    padding-top: 8rem;
  `};
  ${media.mobile`
    width: 100%;
    padding: 8.5rem 1rem 0 1rem;
  `};
`

const Intro = styled.div`
  width: 100%;
  padding: 15px 14px;
  margin-bottom: 14px;
  background-color: #f6f6f8;
  border: 1px solid #e2e2e9;
  border-radius: 4px;
`

const Title = styled.h1`
  font-size: 1.5em;
  color: #000000;
  margin-bottom: 20px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #595757;
`

const CtrlBar = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 15px 14px;
  margin-bottom: 14px;
  margin-top: -10px;
`

const DatePickerWrapper = styled.div`
  width: auto;
`

const DateWrapper = styled.input`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  background-image: none;
  border: 1px solid #ccc;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 4px;
`

const Loading = styled.div`
  width: 25px;
  height: 25px;
  background-image: url(${LoadingGif});
  background-size: contain;
  margin-left: 18px;
`

const DropDownItem = styled.div`
  padding-right: 1rem;
`

const SubmitButton = styled.button`
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  border: none;
  cursor: ${({ disable }) => (disable ? "" : "pointer")};
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 14px;

  &:focus {
    color: #fff;
    outline: none;
    border: solid 1px #ffc13c;
    background-color: #3276b1;
  }
`

function ArticleReads() {
  const { t } = useTranslation(["body", "documentTitle"])
  const [articleReads, setArticleReads] = useState({
    revenues: null,
    errorMessage: null,
  })
  const [title, setTitle] = useState(null)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [isLoading, setIsLoading] = useState(false)

  const { user } = useContext(AuthContext)
  const titles = user["titles"].map(value => {
    return { key: value["id"], value: value["name"] }
  })

  const setValue = (fnstring, value) => {
    setTitle(value)
  }

  const logEvent = () => {
    const start = dayjs(startDate).format("YYYY-MM-DD")
    const end = dayjs(endDate).format("YYYY-MM-DD")
    const eventProperties = {
      "magazine id": title,
      "start date": start,
      "end date": end,
    }
    window.amplitude
      .getInstance()
      .logEvent("queried article reads", eventProperties)
  }

  const submit = async () => {
    try {
      if (title && startDate && endDate) {
        setIsLoading(true)

        const res = await FetchArticleReadsApi(title, startDate, endDate)
        if (res.status === 200) {
          setArticleReads({
            revenues: res.data,
            errorMessage: null,
          })
          logEvent()
        } else {
          setArticleReads({
            revenues: null,
            errorMessage: res.message,
          })
        }
      } else {
        throw Error("date is invalid")
      }
    } catch (error) {
      if (!title) {
        alert(t("panel.message.chooseMagazine"))
      } else if (!startDate) {
        alert(t("panel.message.chooseStartDate"))
      } else if (!endDate) {
        alert(t("panel.message.chooseEndDate"))
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onRangeChange = range => {
    if (range[1]) {
      const start = new Date(range[0])
      const end = new Date(range[1])
      const diffInDays = Math.round((end - start) / (1000 * 60 * 60 * 24))

      if (diffInDays > 30) {
        alert("查詢區間不可超過30日")
        return
      }
    }

    setDateRange(range)
  }

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <DateWrapper
        ref={ref}
        value={props.value}
        placeholder={props.placeholder}
        onClick={props.onClick}
        onChange={props.onChange}
      />
    )
  })

  return (
    <React.Fragment>
      <DocumentTitle title={t("documentTitle:articleReads")} />
      <Wrapper>
        <Intro>
          <Title>{t("manual.articleReads.title")}</Title>
          <Description>{t("manual.articleReads.description")}</Description>
        </Intro>
        <CtrlBar>
          <DropDownItem>
            <DropDown
              data={titles}
              defaultValue={t("panel.dropDown.magazine.placeholder")}
              selectedValue={title}
              tag="title"
              setValue={(f, v) => {
                setValue(f, v)
              }}
            />
          </DropDownItem>
          <DatePickerWrapper>
            <DatePicker
              placeholderText={t("panel.dropDown.dateRange.label")}
              dateFormat="yyyy-MM-dd"
              selectsRange={true}
              peekNextMonth={true}
              startDate={startDate}
              endDate={endDate}
              minDate={new Date("2020-01-01")}
              maxDate={new Date()}
              onChange={onRangeChange}
              customInput={<CustomInput />}
            />
          </DatePickerWrapper>
          {isLoading ? (
            <Loading />
          ) : (
            <SubmitButton
              onClick={e => {
                e.preventDefault()
                submit()
              }}
            >
              {t("panel.button.beginAnalysis")}
            </SubmitButton>
          )}
        </CtrlBar>
        <ArticleReadsTable data={articleReads} />
      </Wrapper>
    </React.Fragment>
  )
}

export default ArticleReads
